<template>
  <div
    class="menu_content_layout">
    <!-- Start big devices menu -->
    <v-layout
      v-if="smUp"
      :class="{'menu_background_color': offsetTop > 20}"
      row>
      <v-spacer></v-spacer>
        <!-- Start logo image -->
        <v-spacer></v-spacer>
          <img
            :src="sofex_title" 
            class="menu_logo"
            alt=""
          >
        <v-spacer></v-spacer>
        <!-- End long image -->
      <v-spacer></v-spacer>   
    </v-layout>
    <!-- End big devices menu -->
    <!-- Start small devices menu -->
    <v-layout
      row
      justify-center
      v-else
      :class="{'menu_background_color': offsetTop > 20}">
      <!-- Start logo image -->
      <v-spacer></v-spacer>
        <img
          :src="sofex_title" 
          class="menu_logo_mobile"
          alt=""
        >
      <v-spacer></v-spacer>
      <!-- Start menu for mobile -->
      <v-menu>
        <template v-slot:activator="{ on }">
          <!-- Start button menu -->
          <v-btn
              v-on="on"
              class="burger_menu"
              right
              absolute
              text
              fab
              small>
              <v-icon
                color="white">
                mdi-menu
              </v-icon>
            </v-btn>
            <!-- End menu button -->
          </template>
          <!-- Start menu content for mobile -->
          <v-card
            class="menu_mobile_wrapper"
            dark
          >
            <v-list color="transparent">
            <!-- Start list of elements -->
              <!-- Start example option -->
              <v-list-item
                @click="$vuetify.goTo('#faq')"
              >
                <v-list-item-title 
                  v-text="texts.exampleText" 
                  class="menu_options_text"
                />
              </v-list-item>
              <!-- End list of elements -->
            </v-list>
          </v-card>
          <!-- End menu content for mobile -->
        </v-menu>
        <!-- End menu for mobile -->
    </v-layout>
    <!--- End small devices menu -->
  </div>
</template>
<script>
  export default {
    data: () => ({
      sofex_title: '/static/SOFEX_LOGO_WHITE.png'
    }),
    methods: {
      setAuthentication () {
        this.$store.commit('toggle_auth')
      }
    },
    props: {
      texts: Object
    },
    computed: {
      smUp () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      offsetTop () {
        return this.$store.state.offsetTop
      }
    }
  }
</script>
<style scoped>
  /**. style for logo in mobile */
  .menu_logo_mobile {
    height: 100%;
    width: 100%;
    max-height: 180px;
    max-width: 160px
  }
  /**. style for logo in computer devices */
  .menu_logo {
    margin-right: 40px;
    height: 100%;
    width: 100%;
    max-height: 220px;
    max-width: 200px;
  }  
  /** style for manu element options */
  .menu_options_text {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: white;
    font-weight: bold;
    margin: 5px;
  }
  /** style for menu wrapper */
  .menu_content_layout {
    z-index: 100;
    width: 100%;
    position: fixed;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
  }
  /** style for burger menu in mobile */
  .burger_menu {
    margin-top: 20px
  }
  /** style for the mobile menu wrapper */
  .menu_mobile_wrapper {
    background: rgb(35,143,234);
    background: linear-gradient(180deg, rgba(35,143,234,1) 12%, rgba(79,219,215,1) 100%);
  }
  /** style foor setting a background color if scrolling */
  .menu_background_color {
    background: linear-gradient(180deg, rgba(35,143,234,.8) 12%, rgba(79,219,215,.8) 100%);
  }
</style>